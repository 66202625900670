import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";
import {formatTimeToHHMM} from '@/utils'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false,
    isOverlayActive: false,
    root_url: "https://landing-api-gwr1.onrender.com", //"http://localhost:5001",
    cities: [],
    vehicles: [],
    defaultCity: {},
    userLocation: {
      latitude: null,
      longitude: null,
    },
    filters: {
      location: null,
      pickupDate: null,
      returnDate: null,
      pickupTime: null,
      returnTime: null,
    },
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    activateOverlay(state) {
      state.isOverlayActive = true;
    },
    deactivateOverlay(state) {
      state.isOverlayActive = false;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setVehicles(state, vehicles) {
      state.vehicles = vehicles;
    },
    setDefaultCity(state, city) {
      state.defaultCity = city;
    },
    setUserLocation(state, { latitude, longitude }) {
      state.userLocation = { latitude, longitude };
    },
    setFilter(state, { filter, value }) {
      state.filters[filter] = value;
    },
  },
  actions: {
    async fetchCities({ commit }) {
      try {
        const cities = await api.location.getClosestCities();
        commit("setCities", cities);
      } catch (error) {
        console.error("Error getting cities:", error);
      }
    },
    async fetchVehicleModels({ commit }) {
      try {
        const models = await api.vehicle.getVehicleModels();
        commit("setVehicles", models);
      } catch (error) {
        console.error("Error getting Vehicles:", error);
      }
    },
    async fetchUserLocation({ commit }) {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            commit("setUserLocation", {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            resolve(true); // Location allowed
          },
          () => {
            commit("setUserLocation", {
              latitude: null,
              longitude: null,
            });
            resolve(false); // Location not allowed
          }
        );
      });
    },

    async setDefaultLocation({ state, commit, dispatch }) {
      if (!state.cities || state.cities.length === 0) {
        await dispatch("fetchCities");
      }

      if (!state.userLocation.latitude || !state.userLocation.longitude) {
        await dispatch("fetchUserLocation");
      }

      let tempLocation = state.cities[0]; // Fallback to the first city
      if (
        state.userLocation.latitude &&
        state.userLocation.longitude &&
        state.cities.length > 0
      ) {
        let closestCity = null;
        let smallestDistance = Infinity;

        state.cities.forEach((city) => {
          const distance = Math.sqrt(
            Math.pow(city.latitude - state.userLocation.latitude, 2) +
              Math.pow(city.longitude - state.userLocation.longitude, 2)
          );

          if (distance < smallestDistance) {
            smallestDistance = distance;
            closestCity = city;
          }
        });

        if (closestCity) {
          tempLocation = closestCity;
        } else {
          console.warn("No closest city found.");
        }
      }

      // Set the default city
      commit("setDefaultCity", tempLocation);
      // Set filter location
      if (!state.location || Object.keys(state.location).length === 0) {
        commit("setFilter", { filter: "location", value: tempLocation });
      }
    },
    setDefaultPickupDate({ commit }) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      commit("setFilter", { filter: "pickupDate", value: tomorrow });
    },
    setDefaultReturnDate({ state, commit }) {
      let referenceDate;

      if (state.filters.pickupDate) {
        referenceDate = new Date(state.filters.pickupDate);
      } else {
        referenceDate = new Date(); // Today's date
      }
      const nextWeek = new Date(referenceDate);
      nextWeek.setDate(referenceDate.getDate() + 7);
      nextWeek.setHours(0, 0, 0, 0);
      commit("setFilter", { filter: "returnDate", value: nextWeek });
    },
    setDefaultTime({ commit, state }) {
      const now = new Date();
      now.setMinutes(0, 0, 0);
      now.setHours(now.getHours() + 2);
      const pickupTime = formatTimeToHHMM(now);
      commit('setFilter', { filter: 'pickupTime', value: pickupTime });
      if(!state.filters.returnTime)
        commit('setFilter', { filter: 'returnTime', value: state.filters.pickupTime });
    },

    setLocation({ commit }, location) {
      commit("setFilter", { filter: "location", value: location });
    },
    setPickupDate({ commit }, date) {
      commit("setFilter", { filter: "pickupDate", value: date });
    },
    setReturnDate({ commit }, date) {
      // const pickupDate = state.filters.pickupDate
      //   ? new Date(state.filters.pickupDate)
      //   : null;
      // let newReturnDate = new Date(date);

      // if (pickupDate && newReturnDate <= pickupDate) {
      //   newReturnDate = new Date(pickupDate);
      //   newReturnDate.setDate(pickupDate.getDate() + 7); // Set returnDate to next week
      // }
      commit("setFilter", { filter: "returnDate", value: date });
    },
    setPickupTime({ commit }, time) {
      commit("setFilter", { filter: "pickupTime", value: time });
    },
    setReturnTime({ commit }, time) {
      commit("setFilter", { filter: "returnTime", value: time });
    },
  },
  getters: {
    // Format dates in 'YYYY-MM-DD' without changing timezone
    pickupDateString: (state) => {
      if (state.filters.pickupDate) {
        return `${state.filters.pickupDate.getFullYear()}-${String(
          state.filters.pickupDate.getMonth() + 1
        ).padStart(2, "0")}-${String(
          state.filters.pickupDate.getDate()
        ).padStart(2, "0")}`;
      }
      return null;
    },
    returnDateString: (state) => {
      if (state.filters.returnDate) {
        return `${state.filters.returnDate.getFullYear()}-${String(
          state.filters.returnDate.getMonth() + 1
        ).padStart(2, "0")}-${String(
          state.filters.returnDate.getDate()
        ).padStart(2, "0")}`;
      }
      return null;
    },
  },
});

export default store;

<template>
  <svg width="100%" height="100%" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M17 8.4569C17 -0.596602 3.66669 -0.596669 3.66669 8.4569C3.66669 11.1073 5.07761 13.4093 6.6013 15.1226C7.89233 16.5743 8.53784 17.3001 10.3335 17.3001C12.1292 17.3001 12.7747 16.5742 14.0657 15.1225C15.5892 13.4092 17 11.1072 17 8.4569ZM10.3334 10.8334C11.7141 10.8334 12.8334 9.71415 12.8334 8.33344C12.8334 6.95273 11.7141 5.83344 10.3334 5.83344C8.95264 5.83344 7.83335 6.95273 7.83335 8.33344C7.83335 9.71415 8.95264 10.8334 10.3334 10.8334Z"
      fill="#D6D2CD" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M4.41669 8.4569C4.41669 6.39227 5.16987 4.90122 6.25339 3.92026C7.34967 2.92775 8.83025 2.41674 10.3334 2.41675C11.8365 2.41675 13.317 2.92776 14.4133 3.92028C15.4968 4.90125 16.25 6.39229 16.25 8.4569C16.25 10.8405 14.9762 12.97 13.5052 14.6241C12.8386 15.3738 12.4231 15.833 11.9743 16.1307C11.5724 16.3972 11.1133 16.5501 10.3335 16.5501C9.55372 16.5501 9.09461 16.3973 8.69269 16.1307C8.24395 15.8331 7.82841 15.3738 7.16174 14.6242C5.69062 12.9701 4.41669 10.8406 4.41669 8.4569ZM17.75 8.4569C17.75 5.99476 16.8365 4.09073 15.4201 2.80831C14.0163 1.53744 12.1636 0.916753 10.3334 0.916748C8.50312 0.916743 6.65038 1.53743 5.24665 2.80829C3.83017 4.09071 2.91669 5.99475 2.91669 8.4569C2.91669 11.374 4.4646 13.8486 6.04087 15.621L6.0885 15.6746C6.69347 16.3551 7.23442 16.9635 7.86365 17.3808C8.55617 17.8401 9.31766 18.0501 10.3336 18.0501C11.3495 18.0501 12.1109 17.84 12.8034 17.3807C13.4326 16.9634 13.9736 16.3549 14.5785 15.6745L14.6261 15.6209C16.2023 13.8485 17.75 11.3739 17.75 8.4569ZM8.58335 8.33344C8.58335 7.36694 9.36686 6.58344 10.3334 6.58344C11.2999 6.58344 12.0834 7.36694 12.0834 8.33344C12.0834 9.29994 11.2999 10.0834 10.3334 10.0834C9.36686 10.0834 8.58335 9.29994 8.58335 8.33344ZM10.3334 5.08344C8.53843 5.08344 7.08335 6.53852 7.08335 8.33344C7.08335 10.1284 8.53843 11.5834 10.3334 11.5834C12.1283 11.5834 13.5834 10.1284 13.5834 8.33344C13.5834 6.53852 12.1283 5.08344 10.3334 5.08344Z"
      fill="#54504C" />
  </svg>

</template>

<script>
export default {
  name: "PinIcon",
};
</script>
<template>
    <div class="flex justify-center bg-cover bg-no-repeat  rounded-xl overflow-hidden pt-4 md:pt-28 pb-[450px] md:pb-96 px-10 my-10" :style="{
        backgroundImage: `url(${require('@/assets/img/ready-to-get.jpeg')})`,
        backgroundPosition: 'center 22%',
    }">
        <div class="flex flex-col items-center text-center pb-24 md:pb-0 gap-10 md:max-w-[22.25rem]">
            <div class="font-medium text-heading tracking-negative-3 text-black">
                Ready to get on the road?
            </div>
            <CtaButton size="large" @click="handleBookNow">
                Book Now
            </CtaButton>
        </div>
    </div>
</template>

<script>
import CtaButton from '@/components/base/CtaButton.vue'
export default {
    name: 'CtaSection',
    components:{
        CtaButton
    },
    methods:{
        handleBookNow(){
            window.open(`${process.env.VUE_APP_RESLA_APP_ROOT_URL}/search`,'_blank');
        }
    }
}
</script>
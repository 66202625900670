<template>
  <div class="w-full relative">
    <TextInput :id="`datepicker-input-${id}`" class="w-full" :size="compact ? 'small' : 'large'"
      :placeholder="placeHolder" :label="label ? label : ''" :is-rounded="rounded" :is-border="border" icon readonly
      :value="displayDate" input-class="cursor-pointer" @click="modalToggle = true">
    </TextInput>
    <Modal enable-blur :id="`datepicker-modal-${id}`" class="!items-end md:!items-center !h-full" title="Change Dates"
      :value="modalToggle" :modal-class="'max-w-max !p-0'" :content-class="'!p-0'" :show-header="false"
      :show-footer="false" @input="(value) => modalToggle = value">
      <div class="flex flex-col">
        <!--modal header-->
        <div class="flex items-center justify-between border-b border-primary-700 p-4">
          <p class="text-3xl leading-8 text-primary-100 tracking-negative-3 font-medium">{{ modalTitle }}</p>
          <button type="button"
            class="bg-transparent hover:bg-gray-200 text-primary-300 hover:text-gray-900 rounded-md text-sm size-6 ms-auto inline-flex justify-center items-center"
            @click="closeModal()">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13" />
            </svg>
          </button>
        </div>
        <!-- content- RangePicker component -->
        <NewRangepicker
          class="p-4" :id="`datepicker-input-${id}`"
          :date-value="tempDateValue"
          :modal-visible="modalToggle"
          :disabled-dates="disabledDates"
          :time-value="tempTimeValue"
          :max-date="maxDate"
          @update:date="setDates"
          @update:time="setTimes"
        />
        <!--modal footer-->
        <div class="flex items-center justify-end gap-6 border-t border-primary-700 p-4">
          <CtaButton variant="secondary" class="w-full" @click="closeModal()">Cancel</CtaButton>
          <CtaButton variant="primary" class="w-full whitespace-nowrap" @click="applyDates">Apply Dates</CtaButton>
        </div>
      </div>
    </Modal>
  </div>

</template>

<script>
import { mapState } from 'vuex';

import Modal from '../base/Modal.vue';
import CtaButton from '../base/CtaButton.vue';
import TextInput from '../base/TextInput.vue';
import NewRangepicker from '../base/RangepickerNew.vue';
const formatOptions = {
  month: "short",
  day: "numeric",
};
export default {
  name: 'DatepickerModal',
  components: {
    Modal,
    NewRangepicker,
    CtaButton,
    TextInput,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    modalTitle: {
      type: String,
      default: 'Change Dates'
    },
    label: {
      type: String,
    },
    placeHolder: {
      type: String,
      default: "Date",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    dateValue: {
      type: Object,
      default: () => ({
        startDate: new Date(),
        endDate: null,
      }),
    },
    timeValue: {
      type: Object,
      default: () => ({
        startTime: '00:00',
        endDate: null,
      }),
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
    maxDate: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      modalToggle: false,
      tempDateValue: {
        startDate: null,
        endDate: null,
      },
      tempTimeValue: {
        startTime: null,
        endTime: null,
      },
    };
  },
  watch: {
    modalToggle(newValue) {
      if (newValue) {
      this.tempDateValue = {...this.dateValue};
      this.tempTimeValue = {...this.timeValue};
    }
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.modalToggle = false
    },
    setDates({ startDate, endDate }) {
      this.tempDateValue.startDate = startDate
      this.tempDateValue.endDate = endDate
    },
    setTimes({ startTime, endTime }) {
      this.tempTimeValue.startTime = startTime
      this.tempTimeValue.endTime = endTime
    },
    applyDates() {
      this.$emit('apply', {
        dateValue: this.tempDateValue,
        timeValue: this.tempTimeValue,
      });
      this.closeModal();
    },
  },
  computed: {
    ...mapState('search', ['filters']),
    displayDate() {
      return new Intl.DateTimeFormat("en-US", formatOptions).format(this.dateValue.startDate) + "-" + new Intl.DateTimeFormat("en-US", formatOptions).format(this.dateValue.endDate);
    },
  },
};
</script>

<style scoped>
/* Add any custom styling here if necessary */
</style>
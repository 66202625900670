<template>
  <div class="flex items-center justify-center rounded-xl mt-8 lg:mt-7 w-full sm:h-full h-[15rem] relative overflow-hidden">
    <video
      controls
      class="rounded-xl w-full h-full object-cover"
      poster="../../assets/img/hero-video-poster.png"
      style="aspect-ratio: 16/8; max-height: 100%;"
    >
      <source
        src="https://public-resla-assets.s3.us-east-1.amazonaws.com/Homepage+Video.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: "IntroVideo",
};
</script>

<template>
    <div class="flex flex-col items-center justify-center w-full mt-16 lg:mt-24">
        <div class="flex flex-col justify-self-center items-center justify-center gap-6 max-w-lg">
            <div class="font-medium text-heading-1 leading-10 text-resla-ebony-20 tracking-negative-3 text-center">
                Models</div>
            <div class="text-resla-ebony-40 text-xl leading-8 font-normal text-center font-urbanist">With its advanced
                crash avoidance
                technology and high-strength reinforcement structure, you can feel confident and secure behind the
                wheel.</div>
        </div>
        <div class="mt-10 lg:mt-20 flex flex-col lg:flex-row gap-6 w-full">
            <template v-if="filteredModels.length > 0">
                <ModelCard v-for="model in filteredModels" :key="model.name" :model-detail="model"
                    :url="constructUrl(model)"></ModelCard>
            </template>
            <template v-else>
                <SkeletonLoader width="100%" height="670px" rounded></SkeletonLoader>
                <SkeletonLoader width="100%" height="670px" rounded></SkeletonLoader>
            </template>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

import ModelCard from '@/components/base/ModelCard.vue'
import SkeletonLoader from '../base/SkeletonLoader.vue';
export default {
    name: "ModelsSection",
    components: {
        ModelCard,
        SkeletonLoader
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState(["vehicles", "filters"]),
        ...mapGetters(["pickupDateString", "returnDateString"]),

        filteredModels() {
            if (this.vehicles) {
                return this.vehicles
                    .filter(vehicle => vehicle.name === 'Model 3' || vehicle.name === 'Model Y')
                    .map(vehicle => ({
                        ...vehicle,
                        colors: ['paint-red', 'paint-blue', 'paint-black', 'paint-pearl', 'paint-silver'],
                        rent: 39
                    }));
            }
            return null
        }
    },
    methods: {
        constructUrl(model) {
            let city = 'Nashville, TN'
            if (this.filters.location?.name){
                city = `${this.filters.location.name}, ${this.filters.location.state}`;
            }
            return `${process.env.VUE_APP_RESLA_APP_ROOT_URL}/search?city=${encodeURIComponent(city)}&pickupDate=${this.pickupDateString}&returnDate=${this.returnDateString}&model=${model.name}`
        }
    }
};
</script>
const agreementService = (api) => ({
    getAgreement: async (params) => {
      return api.request({
        method: "get",
        url: `/api/v1/contracts/`,
        params: params,
      });
    },
  });
  
  export default agreementService;
  
<template>
    <div class="flex flex-col w-full gap-10 mt-14 lg:mt-20">
        <div class="flex items-center justify-between px-6 lg:px-20">
            <div class="text-resla-ebony-10 text-heading-1 leading-[40px] font-medium -trackinng-[3%]">Search by location</div>
            <!-- Custom Carousel Control Buttons -->
            <div class="flex space-x-4">
                <CtaButton size="small" variant="secondary" class="size-11" @click="prev">
                    <ChevronLeft class="size-6"></ChevronLeft>
                </CtaButton>
                <CtaButton size="small" variant="secondary" class="size-11" @click="next">
                    <ChevronLeft class="size-6 rotate-180"></ChevronLeft>
                </CtaButton>
            </div>

        </div>
        <div class="location-carousel w-full overflow-hidden border border-resla-ebony-70">
            <div  class="location-inner" ref="inner" :style="innerStyles">
                <template v-if="cards.length > 0">
                <LocationCard v-for="(location, index) in cards" :key="index" :location="location" :url="getLocationUrl(location)"></LocationCard>
                </template>
                <template v-else>
                    <SkeletonLoader v-for="n in 8" :key="n" width="240px" height="170px" class="ml-0.5"></SkeletonLoader>
                </template>
            </div>
        </div>
    </div>


</template>

<script>
import { mapState } from 'vuex';
import ChevronLeft from '@/components/icons/ChevronLeft.vue'
import LocationCard from '@/components/base/LocationCard.vue'
import CtaButton from '@/components/base/CtaButton.vue';
import SkeletonLoader from '@/components/base/SkeletonLoader.vue';

export default {
    name:'LocationsSearch',
    components:{
        ChevronLeft,
        LocationCard,
        CtaButton,
        SkeletonLoader
    },
    data() {
        return {
            cards: [],
            innerStyles: {},
            step: '',
            transitioning: false
        }
    },

    computed:{
        ...mapState(["cities"]),
    },
    watch:{
      cities(value){
        this.cards = value
        this.setStep()
        this.resetTranslate()
      }
    },
    methods: {
        getLocationUrl(location) {
            let city = 'Nashville, TN'
            if (location?.name){
                city = `${location.name}, ${location.state}`;
            }
            return `${process.env.VUE_APP_RESLA_APP_ROOT_URL}/search?city=${encodeURIComponent(city)}`
        },
        setStep() {
            const innerWidth = this.$refs.inner.scrollWidth
            const totalCards = this.cards.length
            this.step = `${innerWidth / totalCards}px`
        },

        next() {
            if (this.transitioning) return

            this.transitioning = true

            this.moveLeft()

            this.afterTransition(() => {
                const card = this.cards.shift()
                this.cards.push(card)
                this.resetTranslate()
                this.transitioning = false
            })
        },

        prev() {
            if (this.transitioning) return

            this.transitioning = true

            this.moveRight()

            this.afterTransition(() => {
                const card = this.cards.pop()
                this.cards.unshift(card)
                this.resetTranslate()
                this.transitioning = false
            })
        },

        moveLeft() {
            this.innerStyles = {
                transform: `translateX(-${this.step})
                      translateX(-${this.step})`
            }
        },

        moveRight() {
            this.innerStyles = {
                transform: `translateX(${this.step})
                      translateX(-${this.step})`
            }
        },

        afterTransition(callback) {
            const listener = () => {
                callback()
                this.$refs.inner.removeEventListener('transitionend', listener)
            }
            this.$refs.inner.addEventListener('transitionend', listener)
        },

        resetTranslate() {
            this.innerStyles = {
                transition: 'none',
                transform: `translateX(-${this.step})`
            }
        }
    },
    mounted() {
        this.cards = this.cities
        this.setStep()
        this.resetTranslate()
    },
    created(){
        this.cards = this.cities
        
    }
}
</script>

<style scoped>
.location-inner {
    transition: transform 0.2s;
    white-space: nowrap;
    display: flex;
}
</style>
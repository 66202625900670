<template>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="7.5" fill="#D6D2CD" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M4.697 1.1363C4.98989 1.42919 4.98989 1.90406 4.697 2.19696L2.197 4.69696C1.9041 4.98985 1.42923 4.98985 1.13634 4.69696C0.843445 4.40406 0.843445 3.92919 1.13634 3.6363L3.63634 1.1363C3.92923 0.843403 4.4041 0.843403 4.697 1.1363ZM3.25 9.99996C3.25 6.27204 6.27208 3.24996 10 3.24996C13.7279 3.24996 16.75 6.27204 16.75 9.99996C16.75 13.7279 13.7279 16.75 10 16.75C6.27208 16.75 3.25 13.7279 3.25 9.99996ZM10 1.74996C5.44365 1.74996 1.75 5.44361 1.75 9.99996C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 9.99996C18.25 5.44361 14.5563 1.74996 10 1.74996ZM10.75 6.66663C10.75 6.25241 10.4142 5.91663 10 5.91663C9.58579 5.91663 9.25 6.25241 9.25 6.66663V9.99996C9.25 10.2507 9.37533 10.4849 9.58398 10.624L12.084 12.2907C12.4286 12.5204 12.8943 12.4273 13.124 12.0827C13.3538 11.738 13.2607 11.2724 12.916 11.0426L10.75 9.59857V6.66663ZM15.303 2.19696C15.0101 1.90406 15.0101 1.42919 15.303 1.1363C15.5959 0.843403 16.0708 0.843403 16.3637 1.1363L18.8637 3.6363C19.1566 3.92919 19.1566 4.40406 18.8637 4.69696C18.5708 4.98985 18.0959 4.98985 17.803 4.69696L15.303 2.19696Z"
        fill="#44403C" />
    </svg>
  </template>
  
  <script>
  export default {
    name: "TimeCircleIcon",
  };
  </script>
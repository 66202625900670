<template>
  <div class="flex flex-col items-center justify-center w-full gap-4">
    <h1
      class="font-medium text-resla-ebony-10 text-[56px] mt-9 mb-14 tracking-negative-3 leading-[56px] block text-center">
      Rent a Tesla Guaranteed.
    </h1>
    <div
      class="flex w-full flex-col lg:flex-row items-center justify-center border border-resla-ebony-70 rounded-lg bg-resla-ebony-90">
      <AutoComplete class="w-full " id="location-se" :compact="false" label="Location" placeHolder="Location"
        :picker-class="'max-w-md'" :value="filters.location" :options="cityOptions" value-key="name"
        @input="handleLocation" />
        <DatepickerModal id="search-date-selection"  label="Dates" :date-value="{ startDate: filters.pickupDate, endDate: filters.returnDate }"
        :time-value="{ startTime: filters.pickupTime, endTime: filters.returnTime }" @apply="handleBookingDateTime"></DatepickerModal>
      <div class="py-2.5 lg:py-0 px-2.5 bg-resla-ebony-90 w-full lg:w-max rounded-b-lg lg:rounded-none">
        <CtaButton trailing-icon size="small" class="hidden lg:block"
          :is-disabled="!filters.location || !filters.pickupDate || !filters.returnDate" @click="handleSearch">
        </CtaButton>
        <CtaButton trailing-icon size="small" class="block lg:hidden w-full"
          :is-disabled="!filters.location || !filters.pickupDate || !filters.returnDate" @click="handleSearch">View
          Vehicles
        </CtaButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AutoComplete from "@/components/AutoComplete.vue";
import DatepickerModal from "../base/DatepickerModal.vue";
import CtaButton from "@/components/base/CtaButton.vue";
export default {
  name: "HeroSection",
  components: {
    AutoComplete,
    DatepickerModal,
    CtaButton,
  },
  computed: {
    ...mapState(["cities", "filters"]),
    ...mapGetters(['pickupDateString', 'returnDateString']),
    cityOptions() {
      return this.cities.map((city, index) => ({
        id: index,
        label: `${city.name}, ${city.state}`,
        value: city,
      }));
    },
  },
  methods: {
    ...mapActions(["setLocation", "setPickupDate", "setReturnDate","setPickupTime","setReturnTime"]),
    handleLocation(value) {
      this.setLocation(value)
    },
    handleBookingDates({ startDate, endDate }) {
      this.setPickupDate(startDate)
      this.setReturnDate(endDate)
    },
    handleBookingTime({startTime,endTime}){
      this.setPickupTime(startTime)
      this.setReturnTime(endTime)
    },
    handleBookingDateTime({ dateValue, timeValue }) {
      this.handleBookingDates(dateValue)
      this.handleBookingTime(timeValue)
    },
    handleSearch() {
      if (this.filters.location && this.filters.pickupDate && this.filters.returnDate) {
        const city = `${this.filters.location.name}, ${this.filters.location.state}`;
        const url =  `${process.env.VUE_APP_RESLA_APP_ROOT_URL}/search?city=${encodeURIComponent(city)}&pickupDate=${this.pickupDateString}&returnDate=${this.returnDateString}`;
        window.open(url, '_blank')
      } else {
        console.log('Location or dates are missing.');
      }
    },
  },
};
</script>

<template>
  <div class="relative">
    <div v-if="$slots.button" @click="toggleDropdown">
      <slot name="button"></slot>
    </div>
    <CtaButton v-else variant="tertiary" @click="toggleDropdown">
      Models
    </CtaButton>
    <div v-show="isOpen" :class="mergedDropDownClass">
      <div
        class="grid grid-cols-2 lg:grid-cols-4 bg-primary-1000 gap-x-4 gap-y-5 lg:border border-resla-ebony-70 p-6 md:rounded-xl">
        <template v-if="filteredModels.length > 0">
          <ModelLink v-for="model in filteredModels" :key="model.name" :imgSrc="model.public_image_link"
            :name="model.name" @click="handleModelLink(model)" />
        </template>
        <template v-else>
          <SkeletonLoader v-for="item in 4" :key="item" width="100%" height="203px" rounded></SkeletonLoader>
        </template>
      </div>
      <div class="flex lg:hidden p-6 !pt-4 bg-primary-1000">
        <CtaButton to="#" variant="secondary" trailing-icon class="w-full">Sign In</CtaButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { twMerge } from "tailwind-merge";
import ModelLink from "@/components/base/ModelLink.vue";
import CtaButton from "./base/CtaButton.vue";
import SkeletonLoader from './base/SkeletonLoader.vue';

export default {
  name: "NavModels",
  components: {
    ModelLink,
    CtaButton,
    SkeletonLoader
  },
  props: {
    dropdownClass: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
      blurTimeout: false,
    };
  },
  computed: {
    ...mapState(["vehicles", "filters"]),
    ...mapGetters(['pickupDateString', 'returnDateString']),
    mergedDropDownClass() {
      return twMerge(
        "absolute z-50 left-1/2 transform -translate-x-1/2 top-[82px]",
        this.dropdownClass
      );
    },
    filteredModels() {
      if (this.vehicles) {
        return this.vehicles
          .filter(vehicle => vehicle.name === 'Model 3' || vehicle.name === 'Model Y' || vehicle.name === 'Model S' || vehicle.name === 'Model X')
      }
      return null
    }
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        document.addEventListener("click", this.onClickOutside);
      } else {
        document.removeEventListener("click", this.onClickOutside);
      }
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      setTimeout(() => {
        this.$store.commit(
          this.isOpen ? "activateOverlay" : "deactivateOverlay"
        );
      }, 100);
    },
    closeDropdown() {
      this.isOpen = false;
      if (this.$store.state.isOverlayActive) {
        this.$store.commit("deactivateOverlay");
      }
    },
    onClickOutside(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        event.stopPropagation();
        this.closeDropdown();
      }
    },
    handleModelLink(model) {
      this.closeDropdown()
      let city = 'Nashville, TN'
      if (this.filters.location?.name) {
        city = `${this.filters.location.name}, ${this.filters.location.state}`;
      }
      window.open(`${process.env.VUE_APP_RESLA_APP_ROOT_URL}/search?city=${encodeURIComponent(city)}&pickupDate=${this.pickupDateString}&returnDate=${this.returnDateString}&model=${model.name}`, '_blank')
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onClickOutside);
    clearTimeout(this.blurTimeout);
  },
};
</script>

<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="9" fill="#E5E3E0" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.53033 1.46967C5.82322 1.76256 5.82322 2.23744 5.53033 2.53033L2.53033 5.53033C2.23744 5.82322 1.76256 5.82322 1.46967 5.53033C1.17678 5.23744 1.17678 4.76256 1.46967 4.46967L4.46967 1.46967C4.76256 1.17678 5.23744 1.17678 5.53033 1.46967ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12ZM12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V12C11.25 12.2508 11.3753 12.4849 11.584 12.624L14.584 14.624C14.9286 14.8538 15.3943 14.7607 15.624 14.416C15.8538 14.0714 15.7607 13.6057 15.416 13.376L12.75 11.5986V8ZM18.4697 2.53033C18.1768 2.23744 18.1768 1.76256 18.4697 1.46967C18.7626 1.17678 19.2374 1.17678 19.5303 1.46967L22.5303 4.46967C22.8232 4.76256 22.8232 5.23744 22.5303 5.53033C22.2374 5.82322 21.7626 5.82322 21.4697 5.53033L18.4697 2.53033Z"
            fill="#54504C" />
    </svg>
</template>

<script>
export default {
    name: "ClockIcon",
};
</script>
<template>
    <!-- Modal -->
    <div :id="id" tabindex="-1" aria-hidden="true"
      class="fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-modal md:h-full"
      :class="{ 'flex': isVisible, 'hidden': !isVisible, 'w-full h-screen': fullScreen, 'bg-[#110F0C] bg-opacity-0 z-10 backdrop-blur-[6px]': enableBlur }"
      @click.self="handleBackdropClick">
      <div :class="['relative w-full', fullScreen ? ' h-full p-0' : 'p-4 max-w-2xl md:h-auto', modalClass]">
        <!-- Modal content -->
        <div class="relative bg-white  rounded-lg shadow dark:bg-gray-700"
          :class="{ 'flex flex-col h-full': fullScreen }">
          <!-- Modal header -->
          <div v-if="showHeader"
            class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 v-if="showTitle" class="text-xl font-semibold text-gray-900 dark:text-white">
              {{ title }}
            </h3>
            <button v-if="showCloseButton" type="button" @click="hide"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13" />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div :class="['p-4 md:p-5 space-y-4 overflow-auto', fullScreen ? 'flex-1' : '', contentClass]">
            <slot></slot>
          </div>
          <!-- Modal footer -->
          <div v-if="showFooter"
            class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <slot name="footer">
              <!-- Default buttons -->
              <button v-if="showAcceptButton" @click="accept" type="button"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {{ acceptButtonText }}
              </button>
              <button v-if="showDeclineButton" @click="decline" type="button"
                class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                {{ declineButtonText }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Modal } from 'flowbite';
  
  export default {
    name: 'ModalComponent',
    props: {
      id: {
        type: String,
        default: 'default-modal',
      },
      title: {
        type: String,
        default: '',
      },
      options: {
        type: Object,
        default() {
          return {};
        },
      },
      value: {
        type: Boolean,
        default: false,
      },
      fullScreen: {
        type: Boolean,
        default: false,
      },
      modalClass: {
        type: String,
      },
      enableBlur: {
        type: Boolean,
        default: false,
      },
      contentClass: {
        type: String,
      },
      showHeader: {
        type: Boolean,
        default: true,
      },
      showTitle: {
        type: Boolean,
        default: true,
      },
      showCloseButton: {
        type: Boolean,
        default: true,
      },
      showFooter: {
        type: Boolean,
        default: true,
      },
      showAcceptButton: {
        type: Boolean,
        default: true,
      },
      showDeclineButton: {
        type: Boolean,
        default: true,
      },
      acceptButtonText: {
        type: String,
        default: 'I accept',
      },
      declineButtonText: {
        type: String,
        default: 'Decline',
      },
    },
    data() {
      return {
        modal: null,
        isVisible: this.value,
      };
    },
    watch: {
      /**
       * Watch for changes in the 'value' prop to show/hide the modal
       */
      value(newVal) {
        this.isVisible = newVal;
        if (newVal) {
          this.show();
          document.body.style.overflow = 'hidden';
        } else {
          this.hide();
          document.body.style.overflow = '';
        }
      },
      /**
       * Watch for changes in 'isVisible' to emit the 'input' event
       */
      isVisible(newVal) {
        this.$emit('input', newVal);
      },
    },
    mounted() {
      const $targetEl = document.getElementById(this.id);
  
      // Merge default options with user-provided options
      const modalOptions = {
        onHide: () => {
          this.isVisible = false;
          document.body.style.overflow = '';
          this.$emit('input', false);
        },
        onShow: () => {
          this.isVisible = true;
          document.body.style.overflow = 'hidden';
          this.$emit('input', true);
        },
        ...this.options,
      };
  
      this.modal = new Modal($targetEl, modalOptions);
  
      // Initial visibility
      if (this.value) {
        this.show();
      }
    },
    methods: {
      handleBackdropClick(event) {
        if (this.enableBlur && event.target === event.currentTarget) {
          this.hide();
        }
      },
      show() {
        if (!this.isVisible) {
          this.modal.show();
        }
      },
      hide() {
        if (this.isVisible) {
          this.modal.hide();
        }
      },
      accept() {
        this.$emit('accept');
        this.hide();
      },
      decline() {
        this.$emit('decline');
        this.hide();
      },
    },
    beforeDestroy() {
      if (this.modal) {
        this.modal.hide();
        this.modal = null;
        document.body.style.overflow = '';
      }
    },
  };
  </script>
  
  <style scoped>
  /* Add any scoped styles here if needed */
  </style>
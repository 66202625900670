const vehicleService = (api) => ({
    getVehicleModels: async () => {
      return api.request({
        method: "get",
        url: "/api/v1/vehicle_models",
      });
    },
    getVehicle: async (vehicleVin) => {
      return api.request({
        method: "get",
        url: `/api/v1/vehicles/${vehicleVin}`,
      });
    },
    searchVehicles: async ({
      model,
      variant,
      color,
      city,
      pickupAt,
      returnAt,
    }) => {
      return api.request({
        method: "get",
        url: "/api/v1/vehicles",
        params: {
          model,
          variant,
          color,
          city,
          pickupAt,
          returnAt,
        },
      });
    },
  });
  
  export default vehicleService;
  
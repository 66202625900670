<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9999 21C16.9705 21 20.9999 16.9706 20.9999 12C20.9999 7.02944 16.9705 3 11.9999 3C7.02938 3 2.99994 7.02944 2.99994 12C2.99994 16.9706 7.02938 21 11.9999 21Z"
      fill="#E5E3E0" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.9999 3.75C7.44359 3.75 3.74994 7.44365 3.74994 12C3.74994 16.5563 7.44359 20.25 11.9999 20.25C16.5563 20.25 20.2499 16.5563 20.2499 12C20.2499 7.44365 16.5563 3.75 11.9999 3.75ZM2.24994 12C2.24994 6.61522 6.61516 2.25 11.9999 2.25C17.3847 2.25 21.7499 6.61522 21.7499 12C21.7499 17.3848 17.3847 21.75 11.9999 21.75C6.61516 21.75 2.24994 17.3848 2.24994 12ZM8.24987 9.74347C8.24987 8.3664 9.36621 7.25006 10.7433 7.25006H11.2499V6.50006C11.2499 6.08585 11.5857 5.75006 11.9999 5.75006C12.4142 5.75006 12.7499 6.08585 12.7499 6.50006V7.25006H12.9999C14.5187 7.25006 15.7499 8.48127 15.7499 10.0001C15.7499 10.4143 15.4141 10.7501 14.9999 10.7501C14.5857 10.7501 14.2499 10.4143 14.2499 10.0001C14.2499 9.3097 13.6902 8.75006 12.9999 8.75006H12.7499V11.4595L14.0449 11.8912C15.0631 12.2306 15.7499 13.1834 15.7499 14.2566C15.7499 15.6337 14.6335 16.7501 13.2565 16.7501H12.7499V17.5001C12.7499 17.9143 12.4142 18.2501 11.9999 18.2501C11.5857 18.2501 11.2499 17.9143 11.2499 17.5001V16.7501H10.9999C9.48109 16.7501 8.24987 15.5188 8.24987 14.0001C8.24987 13.5858 8.58566 13.2501 8.99987 13.2501C9.41408 13.2501 9.74987 13.5858 9.74987 14.0001C9.74987 14.6904 10.3095 15.2501 10.9999 15.2501H11.2499V12.5406L9.9548 12.1089C8.93663 11.7695 8.24987 10.8167 8.24987 9.74347ZM11.2499 10.9595V8.75006H10.7433C10.1946 8.75006 9.74987 9.19482 9.74987 9.74347C9.74987 10.1711 10.0235 10.5507 10.4291 10.6859L11.2499 10.9595ZM12.7499 13.0406V15.2501H13.2565C13.8051 15.2501 14.2499 14.8053 14.2499 14.2566C14.2499 13.829 13.9763 13.4494 13.5706 13.3142L12.7499 13.0406Z"
      fill="#54504C" />
  </svg>

</template>

<script>
export default {
  name: "DollarIcon",
};
</script>